import React from 'react'
import User from '../manager/User';
import Const from '../Util/Const';
import axios from 'axios';
import { useHistory } from 'react-router';
import './Top.css'
import TargetPass from '../cache/TargetPass';
import Api from '../Util/Api';

class Top extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tbl: [],
      apiBaseTbl: [],
      sortVal: '',
      isOpen: false,
      openId: '',
      targetData: {},
    };
  }

  componentDidMount() {
    document.oncontextmenu = function () {return false;}
    document.getElementsByTagName('html')[0].oncontextmenu = function () {return false;}
    document.body.oncontextmenu = function () {return false;}
    this.getPassList();

    window.addEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    this.setState({
      isOpen: false,
      openId: '',
      targetData: {},
    });
  }

  signOut = () => {
    User.logout();
    this.props.history.push('/sign_in');
  }

  add = () => {
    this.props.history.push('/add');
  }

  cliiiick = (id) => {
    const result = this.state.tbl.find((u) => u.id === id);
    TargetPass.passData = result
    this.props.history.push('/detail');
  }

  deleteAction = (id) => {
    // this.props.history.push('/detail');
    Api.deletePass(id, function(result) {
      if (result) {
        // alert('削除成功');
        // this.props.history.push('/');
        setTimeout(function(){
            window.location.href = '/';
        }, 500);
      } else {
        alert('削除失敗');
        // this.props.history.push('/');
      }
    })
  }

  extractHostDomain = (url) => {
    var host_domain;
    if (url.indexOf("://") > -1) {
      host_domain = url.split('/')[2];
    } else {
      host_domain = url.split('/')[0];
    }
    host_domain = host_domain.split(':')[0];
    return host_domain;
  }

  appendPassList = (elem) => {
    console.log('~~~~~~~~~~~123');
    console.log(elem);
    var icon = elem.icon;
    var iconSrc = process.env.PUBLIC_URL + '/icon_01.png';

    // console.log('~~~~~~~~~~~~~~~~~~~~~~')
    if (icon !== undefined && icon != null && icon.length > 0) {
      if (icon.indexOf("icon_") == 0) {
        if (icon.indexOf("icon_01") == 0 && elem.url !== undefined && elem.url.length > 0) {
          iconSrc = `https://www.google.com/s2/favicons?sz=128&domain_url=${this.extractHostDomain(elem.url)}`;
        } else {
          iconSrc = process.env.PUBLIC_URL + '/' + icon + '.png';
        }
      } else {
        iconSrc = `${Const.API_BASE_URL}/static/images/iconlist/${icon}`;
      }
    }

    var serviceName = elem.service_name;

    var accountId = elem.account_id;
    var accountIdSrc = process.env.PUBLIC_URL + '/copy_id_off.png';
    if (accountId !== undefined && accountId.length > 0) {
      accountIdSrc = process.env.PUBLIC_URL + '/copy_id.png';
    }

    var pass = elem.password;
    if (pass === 'fail') {
      console.log('~~~~~~~~~~パスワードがfail');
      console.log(serviceName);
      console.log(accountId);
    }
    var passSrc = process.env.PUBLIC_URL + '/copy_pass_off.png';
    if (pass !== undefined && pass.length > 0) {
      passSrc = process.env.PUBLIC_URL + '/copy_pass.png';
    }

    var mail = elem.mail_address;
    var mailSrc = process.env.PUBLIC_URL + '/copy_mail_off.png';
    if (mail !== undefined && mail.length > 0) {
      mailSrc = process.env.PUBLIC_URL + '/copy_mail.png';
    }

    var url = elem.url;
    var urlSrc = process.env.PUBLIC_URL + '/copy_url_off.png';
    if (url !== undefined && url.length > 0) {
      urlSrc = process.env.PUBLIC_URL + '/copy_url.png';
    }


    var targetArray = this.state.tbl;
    targetArray.push({
      'id': elem.id,
      'iconSrc': iconSrc,
      'serviceName': serviceName,
      'accountId': accountId,
      'accountIdSrc': accountIdSrc,
      'pass': pass,
      'passSrc': passSrc,
      'mail': mail,
      'mailSrc': mailSrc,
      'url': url,
      'urlSrc': urlSrc,
      'memo': elem.memo,
    });

    this.setState({
      tbl: targetArray,
      apiBaseTbl: targetArray,
    });
  }

  getPassList = () => {
    axios.get(Const.API_BASE_URL + Const.GET_PASS_LIST, { params: { user_id: User.userId() } })
      .then((res) => {
        if (res.data.result == "success") {
          // var resultArray = []
          res.data.response.forEach(element => {
            if (element.password.length > 0) {
              axios.post(Const.API_BASE_URL + Const.SUPER_DECODE, { pass: element.password, user_id: User.userId() })
                .then((res) => {
                  // console.log(res);
                  if (res.data.result == "success") {
                    // console.log(res.data.decode);
                    element.password = res.data.decode
                    this.appendPassList(element);
                    // resultArray.push(element);
                  } else {
                    this.appendPassList(element);
                    // resultArray.push(element);
                  }
                })
                .catch((err) => {
                  // console.log("~~~~~~~~~error0");
                  // console.log(err);
                  this.appendPassList(element);
                  // resultArray.push(element);
                  // this.getPassList();
                });
            } else {
              this.appendPassList(element);
              // resultArray.push(element);
            }
          });
        }
      })
      .catch((err) => {
        // console.log("~~~~~~~~~error1");
        // console.log(err)
        // this.getPassList();
        // alert("ログインに失敗しました。");
      });
  }

  serviceNameAction = () => {
    // console.log('~~~~~~~~~~~123');
    // console.log(this.state.tbl);

    if (this.state.sortVal === 'service1') {
      const tgt = this.state.tbl.sort(function(a,b){
        if(a.serviceName > b.serviceName) return -1;
        if(a.serviceName < b.serviceName) return 1;
        return 0;
      });
      this.setState({
        tbl: tgt,
        sortVal: 'service2',
      });
    } else {
      const tgt = this.state.tbl.sort(function(a,b){
        if(a.serviceName < b.serviceName) return -1;
        if(a.serviceName > b.serviceName) return 1;
        return 0;
      });
      this.setState({
        tbl: tgt,
        sortVal: 'service1',
      });
    }
  }

  /**
   * クリップボードコピー関数
   * 入力値をクリップボードへコピーする
   * [引数]   textVal: 入力値
   * [返却値] true: 成功　false: 失敗
   */
  copyTextToClipboard = (textVal) => {
    // テキストエリアを用意する
    var copyFrom = document.createElement("textarea");
    // テキストエリアへ値をセット
    copyFrom.textContent = textVal;
   
    // bodyタグの要素を取得
    var bodyElm = document.getElementsByTagName("body")[0];
    // 子要素にテキストエリアを配置
    bodyElm.appendChild(copyFrom);
   
    // テキストエリアの値を選択
    copyFrom.select();
    // コピーコマンド発行
    var retVal = document.execCommand('copy');
    // 追加テキストエリアを削除
    bodyElm.removeChild(copyFrom);
    // 処理結果を返却
    return retVal;
  }

  searchChangeAct = (e) => {
    if (e.target.value === '') {
      this.setState({
        tbl: this.state.apiBaseTbl,
      });
      return;
    }
    const result = this.state.apiBaseTbl.filter((value) => (
      value.serviceName.indexOf(e.target.value) > -1
    ));

    this.setState({
      tbl: result,
    });
  }

  render() {
    return (
      <div>
        <div className="kotei_base">
          <div id="kotei">
            <p className="title_lbl" >
              <button className="sign-out-btn" onClick={this.signOut}>サインアウト</button>
              <span>PassHolder</span>
              <button className="add-btn" onClick={this.add}>追加</button>
            </p>
            <input type="text" className="search-text" placeholder="検索" onChange={this.searchChangeAct} />
          </div>
        </div>

        <div className="wrap">
        {this.state.tbl.length > 0 && this.state.tbl.map((data) => {
              return <div className="content-img" onClick={() => {
                  console.log("|||||||||||");
                  console.log(this.state.isOpen);
                  if (this.state.isOpen && this.state.openId === data.id) {
                      this.setState({
                          isOpen: false,
                          openId: '',
                          targetData: {},
                      });
                  } else {
                      this.setState({
                          isOpen: true,
                          openId: data.id,
                          targetData: data,
                      });
                      setTimeout(() => {
                          // document.getElementById(`menu_${data.id}`).focus();
                      }, 100);
                  }
              }}>
                <img src={data.iconSrc} className="img_cls" />
                <p className="content-1">{data.serviceName}</p>
              </div>;
            })}
        </div>

        {this.state.isOpen && this.state.openId === this.state.targetData.id &&
          <div className="menu_list">
            <ul
              id={`menu_${this.state.targetData.id}`}
              className="menu_ul"
              onBlur={() => setTimeout(() => this.setState({
                  isOpen: false,
                  openId: '',
              }), 100)} 
              tabIndex={1}
            >
              <li className="title_menu">{this.state.targetData.serviceName}</li>
              {this.state.targetData.accountId !== '' &&
                <li className="btn_menu" onClick={() => this.copyTextToClipboard(this.state.targetData.accountId)}>アカウントIDをコピー</li>
              }
              {this.state.targetData.pass !== '' &&
                <li className="btn_menu" onClick={() => this.copyTextToClipboard(this.state.targetData.pass)}>パスワードをコピー</li>
              }
              {this.state.targetData.mail !== '' &&
                <li className="btn_menu" onClick={() => this.copyTextToClipboard(this.state.targetData.mail)}>メールアドレスをコピー</li>
              }
              {this.state.targetData.url !== '' &&
                <li className="btn_menu" onClick={() => this.copyTextToClipboard(this.state.targetData.url)}>URLをコピー</li>
              }
              <li className="btn_menu" onClick={() => this.cliiiick(this.state.targetData.id)}>編集</li>
            </ul>
          </div>
        }
      </div>
    )
  }
}


export default Top;