
class Validation {
  is_cellphone = (number) => {
    if (number.match(/^0[9876]0[-]?\d{4}[-]?\d{4}$/)) {
      return true;
    } else {
      return false;
    }
  }
}

export default new Validation();
