import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import SignIn from './view/SignIn';
import SignUp from './view/SignUp';
import Top from './view/Top';
import Detail from './view/Detail';
import Add from './view/Add';
import Auth from './manager/Auth.js';

class App extends Component {
  render() {
    return (
      <div className="App">
        <Router>
          <Switch>
            <Route exact path="/sign_in" component={SignIn} />
            <Route exact path="/sign_up" component={SignUp} />
            <Auth>
              <Switch>
                <Route exact path="/top" component={Top} />
                <Route exact path="/detail" component={Detail} />
                <Route exact path="/add" component={Add} />
                <Redirect from="/" to="/top" />
              </Switch>
            </Auth>
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;