import React from 'react'
import { Link } from 'react-router-dom'
import './Loading.css'

class Loading extends React.Component {
  render() {
    return (
      <div className={`loader_wrapper ${this.props.loading ? '' : 'display_none'}`}>
        <div className="loading_wrap">
          <div className="loader">
            Loading...
          </div>
        </div>
      </div>
    )
  }
}


export default Loading;