import React from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import User from '../manager/User';
import Const from '../Util/Const';
import Validation from '../Util/Validation';
import { SignInPhone, SignInPhoneConfirmCode } from "../manager/FireApp";
import firebase from "firebase";
// import UserData from '../cache/UserData';
import './SignIn.css'
import Loading from '../manager/Loading';


class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: '',
      role: '',
      codeFlag: false,
      debugMode: false, // 手動で変更する項目、trueでSMS認証を飛ばす（基本はfalse）
      loadingFlag: false,
    };
  }

  componentDidMount() {
    firebase.auth().languageCode = 'jp';

    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(this.recaptcha, {
      'size': 'invisible',
    });
    window.recaptchaVerifier.render().then(function (widgetId) {
      window.recaptchaWidgetId = widgetId;
    });
  }


  signIn = () => {
    var mail = document.getElementById('mail').value;
    var pass = document.getElementById('pass').value;
    var ts = this;
    var debugMode = this.state.debugMode;
    ts.setState({loadingFlag: true});

    axios.post(Const.API_BASE_URL + Const.SIGN_IN, { user_id: mail, password: pass })
      .then((res) => {
        if (res.data.result == "success") {
          if (Validation.is_cellphone(mail) && !debugMode) {
            SignInPhone(mail, window.recaptchaVerifier, function(result, confirmationResult) {
              if (result) {
                window.confirmationResult = confirmationResult;
                // UserData.signType = '1';
                // this.props.history.push('/confirm_code');
                ts.setState({
                  userId: res.data.user_id,
                  role: res.data.role,
                  codeFlag: true,
                  loadingFlag: false,
                });
              }
            })
          } else {
            User.login(res.data.user_id, res.data.role);
            ts.setState({loadingFlag: false});
            setTimeout(function(){
              window.location.href = '/';
            }, 500);
          }
        } else {
          ts.setState({loadingFlag: false});
          alert("ログインに失敗しました。");
        }
      })
      .catch((err) => {
        ts.setState({loadingFlag: false});
        alert("ログインに失敗しました。");
      });
  }

  confirmCode = () => {
    var ts = this;
    ts.setState({loadingFlag: true});
    const code = document.getElementById('code').value;
    const uID = this.state.userId;
    const rl = this.state.role;
    SignInPhoneConfirmCode(window.confirmationResult, code, function(result) {
      console.log(result);
        if (result) {
          User.login(uID, rl);
          ts.setState({loadingFlag: false});
          setTimeout(function(){
            window.location.href = '/';
        }, 500);
      }
    })
  }

  render() {
    return (
      <div className="base">
        <Loading loading={this.state.loadingFlag}/>
        <div ref={(ref)=>this.recaptcha=ref}></div>
        <img border="0" src={`${process.env.PUBLIC_URL}/title_logo.png`} alt="PassHolder" className="logo_img"></img>

        {!this.state.codeFlag &&
          <div>
            <input type="text" id="mail" placeholder="電話番号（またはID）" className="text_base mail_text" /><br />
            <input type="password" id="pass" placeholder="password" className="text_base pass_text" /><br />
            <button onClick={this.signIn} className="btn_base" >サインイン</button><br />
            <Link to="/sign_up">アカウントをお持ちではない方はこちら</Link>
          </div>
        }

        {this.state.codeFlag &&
          <div>
            <input type="text" id="code" placeholder="確認コード" className="text_base text_align_center" /><br />
            <button onClick={this.confirmCode}>コード確認</button><br />
          </div>
        }
      </div>
    )
  }
}


export default SignIn;