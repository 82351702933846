class TargetPass {
    passData = {}
    // id = '';
    // userId = '';
    // serviceName = '';
    // accountId = '';
    // mailAddress = '';
    // password = '';
    // icon = '';
    // url = '';
    // memo = '';

    // データクリア
    clearData = () => {
        this.passData = {};
        // this.id = '';
        // this.userId = '';
        // this.serviceName = '';
        // this.accountId = '';
        // this.mailAddress = '';
        // this.password = '';
        // this.icon = '';
        // this.url = '';
        // this.memo = '';
    };
}
  
export default new TargetPass();
  