import React from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import User from '../manager/User';
import Const from '../Util/Const';
import Validation from '../Util/Validation';
import { SignInPhone, SignInPhoneConfirmCode } from "../manager/FireApp";
import firebase from "firebase";
// import UserData from '../cache/UserData';
import './SignUp.css'
import Loading from '../manager/Loading';

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mail: '',
      pass: '',
      codeFlag: false,
      loadingFlag: false,
    };
  }

  componentDidMount() {
    firebase.auth().languageCode = 'jp';

    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(this.recaptcha, {
      'size': 'invisible',
    });
    window.recaptchaVerifier.render().then(function (widgetId) {
      window.recaptchaWidgetId = widgetId;
    });
  }

  signUp = () => {
    var ts = this;
    ts.setState({loadingFlag: true});
    var mail = document.getElementById('mail').value
    var pass = document.getElementById('pass').value
    var passconf = document.getElementById('passconf').value
    
    
    if (pass !== passconf) {
      console.log('ぱすわーどふいっち');
      ts.setState({loadingFlag: false});
      alert('パスワードが一致していません。');
      return;
    }

    if (Validation.is_cellphone(mail)) {
      SignInPhone(mail, window.recaptchaVerifier, function(result, confirmationResult) {
        if (result) {
          window.confirmationResult = confirmationResult;
          ts.setState({
            mail: mail,
            pass: pass,
            codeFlag: true,
            loadingFlag: false,
          });
        } else {
          alert('何らかの理由でアカウント作成に失敗しました。');
          ts.setState({loadingFlag: false});
        }
      })
    } else {
      console.log('電話番号では無い。。。');
      ts.setState({loadingFlag: false});
      alert('電話番号のフォーマットが相違しています。');
    }
  }

  confirmCode = () => {
    var ts = this;
    ts.setState({loadingFlag: true});
    const code = document.getElementById('code').value;
    var mail = this.state.mail;
    var pass = this.state.pass;

    SignInPhoneConfirmCode(window.confirmationResult, code, function(result) {
      console.log(result);
      if (result) {
        axios.post(Const.API_BASE_URL + Const.SIGN_UP, { user_id: mail, password: pass })
          .then((res) => {
            console.log("res.data.result");
            console.log(res.data.result);
            if (res.data.result == "success") {
              axios.post(Const.API_BASE_URL + Const.SIGN_IN, { user_id: mail, password: pass })
                .then((res) => {
                  console.log("res.data.result123");
                  console.log(res.data.result);
                  if (res.data.result == "success") {
                    User.login(res.data.user_id, res.data.role);
                    ts.setState({loadingFlag: false});
                    setTimeout(function(){
                        window.location.href = '/';
                    }, 500);
                  } else {
                    ts.setState({loadingFlag: false});
                    alert("アカウント作成に失敗しました。");
                  }
                })
                .catch((err) => {
                  ts.setState({loadingFlag: false});
                  alert("アカウント作成に失敗しました。");
                });
            }
          })
          .catch((err) => {
            ts.setState({loadingFlag: false});
            alert("アカウント作成に失敗しました。");
          });
      }
    })
  }

  render() {
    return (
      <div className="base">
        <Loading loading={this.state.loadingFlag}/>
        <div ref={(ref)=>this.recaptcha=ref}></div>
        <img border="0" src={`${process.env.PUBLIC_URL}/title_logo.png`} alt="PassHolder" className="logo_img"></img>

        {!this.state.codeFlag &&
          <div>
            <input type="text" id="mail" placeholder="電話番号" className="text_base mail_text" /><br />
            <input type="password" id="pass" placeholder="password" className="text_base pass_text" /><br />
            <input type="password" id="passconf" placeholder="password confirm" className="text_base pass_text" /><br />
            <button onClick={this.signUp} className="btn_base" >サインアップ</button><br />
            <Link to="/sign_in">アカウントをお持ちの方はこちら</Link>
          </div>
        }

        {this.state.codeFlag &&
          <div>
            <input type="text" id="code" placeholder="確認コード" className="text_base" /><br />
            <button onClick={this.confirmCode}>コード確認</button><br />
          </div>
        }
      </div>
    )
  }
}


export default SignUp;