import firebase from "firebase";

/**
 * Fierbaseの初期化処理
 */
export const FireApp = firebase.initializeApp({
  apiKey: "AIzaSyDoNNktqXu3tMTlHxjnKN_aHtBEvklcICo",
  authDomain: "pass-holder.firebaseapp.com",
  databaseURL: "https://pass-holder.firebaseio.com",
  projectId: "pass-holder",
  storageBucket: "pass-holder.appspot.com",
  messagingSenderId: "848504822189",
  appId: "1:848504822189:web:bff1d4fe74f15c0d6de924",
  measurementId: "G-EGSEZD1KYK"
});

/**
 * 電話番号のサインイン処理
 * @param {*} phoneNumber 電話番号の文字列
 * @param {*} recaptcha reCAPTCHAのインスタンス（利用規約の）
 * @param {*} callback コールバック
 */
export const SignInPhone = (phoneNumber, recaptcha, callback) => {
  // 09099999999　→　+819099999999
  var cantryPhone = '+81' + phoneNumber.slice(1);
  console.log(cantryPhone);
  console.log(recaptcha);
  FireApp.auth().signInWithPhoneNumber(cantryPhone, recaptcha).then((confirmationResult) => {
    callback(true, confirmationResult);
  }).catch((error) => {
    console.log('===================SignInPhone');
    console.log(error);
    callback(false, '');
  });
};

/**
 * 電話番号の確認コード
 * @param {*} confirmationResult 
 * @param {*} code 確認コード
 * @param {*} callback コールバック
 */
export const SignInPhoneConfirmCode = (confirmationResult, code, callback) => {
  confirmationResult.confirm(code).then((result) => {
    callback(true);
  }).catch((error) => {
    console.log('=================SignInPhoneConfirmCode');
    console.log(error);
    callback(false);
  });
};
