
import axios from 'axios';
import User from '../manager/User';
import Const from '../Util/Const';

class Api {
  // パスワードのエンコード
  superEncode = (pass, calback) => {
    axios.post(Const.API_BASE_URL + Const.SUPER_ENCODE, { pass : pass, user_id : User.userId() })
      .then((res) => {
        console.log(res.data);
        if (res.data.result == "success") {
          console.log("=====================");
          console.log(res.data.decode);
          calback(res.data.decode);
        } else {
          // alert("データの取得に失敗しました1");
          calback("");
        }
      })
      .catch((err) => {
        console.log(err);
        alert("ログインに失敗しました");
      });
  }

  // パスワード情報の登録、更新
  sample = (targetId) => {
    console.log('~~~~~~~~~sample');
    axios.get(Const.API_BASE_URL + '/target_pass', {
        params: {
          user_id: User.userId(),
          target_id: targetId,
        }
      }).then((res) => {
          console.log(res.data);
          if (res.data.result == "success") {
            console.log("=====================123123");
            console.log(res.data);
            // calback(true);
          } else {
            // alert("データの取得に失敗しました1");
            // calback(false);
          }
        })
        .catch((err) => {
          console.log(err);
          // alert("ログインに失敗しました");
        });
  }

  // パスワード情報の登録、更新
  insertUpdatePass = (parameter, calback) => {
    axios.post(Const.API_BASE_URL + Const.GET_PASS_LIST, parameter)
      .then((res) => {
        console.log(res.data);
        if (res.data.result == "success") {
          console.log("=====================123123");
          console.log(res.data);
          calback(true);
        } else {
          // alert("データの取得に失敗しました1");
          calback(false);
        }
      })
      .catch((err) => {
        console.log(err);
        alert("ログインに失敗しました");
      });
  }

  deletePass = (targetId, calback) => {
    axios.delete(Const.API_BASE_URL + Const.GET_PASS_LIST, {data: {id: targetId, user_id: User.userId()}})
      .then((res) => {
        console.log(res.data);
        if (res.data.result == "success") {
          console.log("=====================999999");
          console.log(res.data);
          calback(true);
        } else {
          // alert("データの取得に失敗しました1");
          calback(false);
        }
      })
      .catch((err) => {
        console.log(err);
        calback(false);
      });
  }
}

export default new Api();
