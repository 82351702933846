import React from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import User from '../manager/User';
import Const from '../Util/Const';
import Api from '../Util/Api';
import TargetPass from '../cache/TargetPass';

class Add extends React.Component {
  componentDidMount() {
    // 読み込み後処理
    console.log('============');
    console.log(TargetPass.aaaa);
  }

  addAction = () => {
    Api.superEncode(document.getElementById('password').value, function(passData) {
      const parameter = {
        user_id: User.userId(),
        service_name: document.getElementById('serviceName').value,
        account_id: document.getElementById('accountId').value,
        mail_address: document.getElementById('mail').value,
        password: passData,
        icon: 'icon_01',
        url: document.getElementById('url').value,
        memo: document.getElementById('memo').value
      };
      Api.insertUpdatePass(parameter, function(result) {
        if (result) {
          setTimeout(function(){
            window.location.href = '/';
          }, 500);
        } else {
          alert("登録失敗");
        }
      })
    })
  }

  render() {
    return (
      <div>
        <h1>Add</h1>
        <input type="text" id="serviceName" placeholder="サービス名" /><br />
        <input type="text" id="accountId" placeholder="アカウントID" /><br />
        <input type="text" id="mail" placeholder="メールアドレス" /><br />
        <input type="password" id="password" placeholder="パスワード" /><br />
        <input type="text" id="url" placeholder="URL" /><br />
        <textarea type="text" id="memo" placeholder="メモ" /><br />
        <button onClick={this.addAction}>データ登録</button>
        <Link to="/">一覧へ戻る</Link>
      </div>
    )
  }
}


export default Add;