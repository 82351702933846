import React from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import User from '../manager/User';
import Const from '../Util/Const';
import TargetPass from '../cache/TargetPass';
import Api from '../Util/Api';
import './Detail.css'

class Detail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPassHide: true,
    };
  }

  componentDidMount() {
    // 読み込み後処理
    if (TargetPass.passData.iconSrc === undefined) {
      this.props.history.push('/');
      return
    }

    document.getElementById('targetId').value = TargetPass.passData.id;
    if (TargetPass.passData.iconSrc.slice(0, 1) === '/') {
      document.getElementById('icon').value = TargetPass.passData.iconSrc.slice(1, TargetPass.passData.iconSrc.length - 4);
    } else if (TargetPass.passData.iconSrc.indexOf(`${Const.API_BASE_URL}/static/images/iconlist/`) !== -1) {
      var pathBase = `${Const.API_BASE_URL}/static/images/iconlist/`
      document.getElementById('icon').value = TargetPass.passData.iconSrc.slice(pathBase.length, TargetPass.passData.iconSrc.length);
    } else {
      document.getElementById('icon').value = TargetPass.passData.iconSrc;
    }
    document.getElementById('serviceName').value = TargetPass.passData.serviceName;
    document.getElementById('accountId').value = TargetPass.passData.accountId;
    document.getElementById('mail').value = TargetPass.passData.mail;
    document.getElementById('password').value = TargetPass.passData.pass;
    document.getElementById('url').value = TargetPass.passData.url;
    document.getElementById('memo').value = TargetPass.passData.memo;

    Api.sample('19');
    // Api.sample(TargetPass.passData.id);
  }

  updateAction = () => {
    Api.superEncode("password123", function(passData) {
      const parameter = {
        id: document.getElementById('targetId').value,
        user_id: User.userId(),
        service_name: document.getElementById('serviceName').value,
        account_id: document.getElementById('accountId').value,
        mail_address: document.getElementById('mail').value,
        password: passData,
        icon: document.getElementById('icon').value,
        url: document.getElementById('url').value,
        memo: document.getElementById('memo').value
      };
      Api.insertUpdatePass(parameter, function(result) {
        if (result) {
          setTimeout(function(){
            window.location.href = '/';
          }, 500);
        } else {
          alert("登録失敗");
        }
      })
    })
  }

  eyeAction = () => {
    this.setState({
      isPassHide: !this.state.isPassHide
    });
  }
  
  render() {
    return (
      <div>
        <div className="kotei_base_det">
          <div id="kotei_det">
            <p className="title_lbl_det" >
              <span>詳細</span>
            </p>
          </div>
        </div>

        <input type="hidden" id="targetId" />
        <input type="hidden" id="icon" />

        <input type="text" id="serviceName" className="text-base" placeholder="サービス名" /><br />
        <input type="text" id="accountId" className="text-base" placeholder="アカウントID" /><br />
        <input type="text" id="mail" className="text-base" placeholder="メールアドレス" /><br />
        <div className="position-relative">
          <input type={this.state.isPassHide ? 'password' : 'text'} id="password" placeholder="パスワード" className="text-base" />
          <button onClick={this.eyeAction} className="eye-btn"><input type="image" className="img" src="/eye.png" /></button>
        </div>
        <input type="text" id="url" className="text-base" placeholder="URL" /><br />
        <textarea type="text" id="memo" className="text-base memo-height" placeholder="メモ" /><br />
        <button onClick={this.updateAction}>データ更新</button>
        <Link to="/">一覧へ戻る</Link>
      </div>
    )
  }
}


export default Detail;