
class Const {
  // API_BASE_URL = 'https://www.api-reigle.com:3453';
  API_BASE_URL = 'https://passholder.reigle-app.com:3453';
  SIGN_IN = '/loginno';
  SIGN_UP = '/createloginno';
  GET_PASS_LIST = '/pass';
  SUPER_DECODE = '/superdecode';
  SUPER_ENCODE = '/superencode';

  ENV = () => {
    if (process.env.NODE_ENV === 'development') {
      return {
        apiKey: "aaaaa",
        apiURL: "aaaaa1"
      }
    } else if (process.env.NODE_ENV === 'test') {
      return {
        apiKey: "aaaaa",
        apiURL: "aaaaa1"
      }
    } else if (process.env.NODE_ENV === 'production') {
      return {
        apiKey: "aaaaa",
        apiURL: "aaaaa1"
      }
    } else {
      return {
        apiKey: "bbbbb",
        apiURL: "bbbbb1"
      }
    }
  }
}

export default new Const();
