class User {
  isLoggedIn = () => localStorage.getItem('isLoggedIn') === 'true';

  // ログイン
  login = (userId, role) => {
    localStorage.setItem('isLoggedIn', true);
    localStorage.setItem('userId', userId);
    localStorage.setItem('role', role);
  };

  userId = () => {
    return localStorage.getItem('userId');
  }

  // ログアウト
  logout = () => {
    if (this.isLoggedIn()) {
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('userId');
      localStorage.removeItem('role');
    }
  };
}
  
export default new User();
